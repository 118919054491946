import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "../../../utils/breakpoints/"
import { colors } from "../../../utils/variables/"

// Icons
import Check from "../../../assets/icons/checkmark-white.svg"

// Downloads
import InstructionsForUse from "../../../assets/downloads/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Instructions-for-Use.pdf"

const StyledTopBullets = styled.div`
		flex: 1 0 30%;
		max-width: 550px;
		min-width: 280px;
		padding-top: 4px;
		width: 100%;
		color: ${colors.white};

	h1 {
		color: ${colors.white};
		font-size: 36px;
		padding: 24px 0px 5px;
		text-align: center;
  
		${breakpoint.small`
			text-align: left;
		`}
	}

	h2 {
		color: ${colors.white};
		font-size: 22px;
		padding-bottom: 36px;
		text-align: center;
  
		${breakpoint.small`
			text-align: left;
		`}
	}

  	h3 {
	 	text-align: center;
		font-size: 19px;
	
		${breakpoint.small`
			display: block;
			text-align: left;
			font-size: 22px;
			`}
		
		&::before {
            content: url(${Check});
			margin-right: 8px;

			${breakpoint.small`
				margin-right: 8px;
			`}
		}
	}

  	.questions {
		font-weight: 700; 
		font-size: 18px;
		text-align: center;
  
		${breakpoint.small`
			text-align: left;
		`}
  	}
  	
	p {
		font-size: 16px;
		line-height: 1.4rem;
		padding-bottom: 36px;
		margin: 0 20px;
		text-align: center;
  
		${breakpoint.small`
			margin-left: 0;
			text-align: left;
		`}
	}
  	
    a {
      color: ${colors.orange};

      &:hover {
        color: ${colors.purpleDark};
      }
    }
    
 .detail {
		${breakpoint.small`
			margin-left: 2rem;
			text-align: left;
		`}
	a {
		  color: ${colors.orange};

		  &:hover {
			color: ${colors.charcoal};
		  }
		}
    }

    ${breakpoint.medium`
        width: 50%;
    `}
  }
`


const TopBullets = props => {
	return (
		<StyledTopBullets>
			<h1>Request a demo</h1>
			<h2>Visby Medical Sexual Health Test</h2>
			<div data-aos="fade-up" data-aos-delay="100">
				<h3>Accurate</h3>
				<p className="detail">Delivers ~97% accuracy, see our <a href={InstructionsForUse} target="_blank" rel="noopener noreferrer">Instructions for Use</a> for details</p>
			</div>
			<div data-aos="fade-up" data-aos-delay="200">
				<h3>Fast</h3>
				<p className="detail">Results in under 30 minutes, while the patient is present.</p>
			</div>
			<div data-aos="fade-up" data-aos-delay="300">
				<h3>CLIA-waived</h3>
				<p className="detail">Easy to use at the point-of-care; no extra instrument or maintenance needed.</p>
			</div>
			<div data-aos="fade-up" data-aos-delay="400">
				<h3>Antibiotic stewardship</h3>
				<p className="detail">Avoid unnecessary antibiotics.</p>
			</div>
			<p className="questions" data-aos="fade-up" data-aos-delay="500"> Questions? Call us 1-833-GoVisby (1-833-468-4729)</p>
		</StyledTopBullets>
	)
}
export default TopBullets
